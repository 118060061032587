import { useState, useEffect } from "react";
import Papa from "papaparse";
import { CSVData } from "../types/index";
import {
    REACT_APP_STAKING_PAYOUT,
  } from "../conf";
const useCSVData = ( paramAccount: string | null) => {
  const [data, setData] = useState<CSVData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const stakingPaid = REACT_APP_STAKING_PAYOUT === "true";

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const response = await fetch("/output1.csv");
        const csvText = await response.text();

        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            if (result.data && paramAccount) {
              const filteredData = (result.data as CSVData[]).filter(
                (row) =>
                  row.address &&
                  row.address.toLowerCase() === paramAccount.toLowerCase()
              );
              setData(filteredData);
            }
          },
        });
      } catch (err) {
        console.error("Error loading CSV:", err);
        setError("Failed to load CSV data");
      }
    };

    if (stakingPaid && paramAccount !== null) fetchCSV();
  }, [ paramAccount, stakingPaid]);

  return { data, error };
};

export default useCSVData;
