import { useEffect, useState } from "react";
import axios from "axios";
import { REACT_APP_REWARD_ENDPOINT } from "../conf";
import { ethers } from "ethers";

interface UseFetchBalanceResult {
  balance: number | null;
  rewardsStaked: boolean;
  loading: boolean;
  error: string | null;
}

interface UseFetchBalanceProps {
  address: Maybe<string>;
  totalPayout: number | undefined;
}

const useFetchBalance = ({
  address,
  totalPayout,
}: UseFetchBalanceProps): UseFetchBalanceResult => {
  const [balance, setBalance] = useState<number | null>(null);
  const [rewardsStaked, setRewardsStaked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        if (REACT_APP_REWARD_ENDPOINT && address && address !== null) {
          setLoading(true);

          const response = await axios.post(
            REACT_APP_REWARD_ENDPOINT,
            {
              jsonrpc: "2.0",
              id: 4,
              method: "eth_getBalance",
              params: [address, "latest"],
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const hexBalance = response.data.result;
          const balanceInEther = ethers.utils.formatEther(hexBalance);
          const balanceInEtherNumber = Math.floor(Number(balanceInEther));
          setBalance(balanceInEtherNumber);
          if (
            totalPayout !== undefined &&
            balanceInEtherNumber >= Math.floor(totalPayout)
          ) {
            setRewardsStaked(true);
          } else {
            setRewardsStaked(false);
          }
        }
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, [address, totalPayout, REACT_APP_REWARD_ENDPOINT]);

  return { balance, rewardsStaked, loading, error };
};

export default useFetchBalance;
