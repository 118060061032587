import "./styles.css";
import { useState, memo, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { GlobalStatistics } from "../GlobalStatistics/GlobalStatistics";
import Boost from "../../assets/Icons/boost.svg";
import "./styles.css";
import StakeSection from "./StakeSection";
import useFetchBalance from "../../hooks/useFetchBalance";
import useCSVData from "../../hooks/useCSVData";
import { WalletButton, StakeDivider } from "../../CustomizedMui";
import MyStatisticsCard from "../LocalStatistics/MyStatisticsCard";
import RewardSection from "./RewardSection";
import Statistics from "../../assets/Icons/statistics.svg";
import { useWallet } from "../../contexts";
import { WalletType } from "../../types";
import {
  REACT_APP_TOTAL_STAKE_STATIS_OFF,
  REACT_APP_RESTRUCTURE_TEXT,
  REACT_APP_LIVENESS_ENDPOINT,
  REACT_APP_STAKING_PAYOUT,
} from "../../conf";
import {
  useLocalStakingStatisticsForLazyQuery,
  useGetBoosterWindowLazyQuery,
} from "../../generated/graphql";
import { StakingData, availableBalanceForStaking, BoosterData } from "./model";

type StakingProps = {
  width: number;
  activeMetamask: boolean;
};

const warning_text =
  "I'm having problems reading the data i need, please try again later.";
const warning_text_restructured =
  "Staking page is being restructured and might show some discrepancies while being redeployed.";

const prepareAddress = (address: string | undefined) => {
  if (address && address.length === 42 && address.startsWith("0x")) {
    return address.substring(2);
  } else {
    return undefined;
  }
};

const Staking = (props: StakingProps) => {
  const { activeMetamask, width } = props;
  const { connect, account, tokenBalance } = useWallet();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramAccount = searchParams.get("account");
  const stakingPaid = REACT_APP_STAKING_PAYOUT === "true";
  const [getLocalStatisticsFn, { data: localStatisticsData }] =
    useLocalStakingStatisticsForLazyQuery();
  const [getBoosterWindowFn, { data: boosterData }] =
    useGetBoosterWindowLazyQuery();
  const stakingStatisticsFor = localStatisticsData?.stakingStatisticsFor;
  const boosterStatisticsFor = boosterData?.getBoosterWindow;
  const [amountToStake, setAmountToStake] = useState<number>(0);
  const [beWarning, setBeWarning] = useState<boolean>(false);
  const [activeBooster, setActiveBooster] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [amountToStakeError, setamountToStakeError] = useState<boolean>(false);

  const address = account || paramAccount;
  const { data } = useCSVData(address);
  const totalPayout =
    data && data.length > 0 ? data[0].total_to_pay : undefined;
  const { rewardsStaked } = useFetchBalance({
    address,
    totalPayout,
  });
  const handleAmountToStake = (amount: number) => {
    if (amount <= availableBalanceForStake) {
      setamountToStakeError(false);
      setAmountToStake(amount);
    } else {
      setamountToStakeError(true);
      setAmountToStake(availableBalanceForStake);
    }
  };

  const model: StakingData = {
    forAddr: stakingStatisticsFor ?? {
      stakedCwebDays: 0,
      currentStakedCweb: 0,
      totalForfeitedCwebCollateral: 0,
      totalAccumulatedL2Rewards: 0,
      totalForfeitedL2Rewards: 0,
      totalStakedCwebCollateral: 0,
      currentBoostStakeCweb: 0,
      currentBoostForfeitedL2: 0,
      totalBoostRewards: 0,
      amountOfStakers: 0,
      totalStakedCweb: 0,
      totalUnstakedCweb: 0,
    },
    tokenBalance,
    amountToStake,
  };
  const booster: BoosterData = {
    booster: boosterStatisticsFor ?? {
      endsAt: 0,
      rewardPool: 0,
      startsAt: 0,
    },
  };
  const availableBalanceForStake = availableBalanceForStaking(model);

  useEffect(() => {
    if (REACT_APP_LIVENESS_ENDPOINT)
      axios.get(REACT_APP_LIVENESS_ENDPOINT).then((response) => {
        if (response.status === 200) {
          setIsReady(true);
        } else {
          setIsReady(false);
        }
      });
  }, []);

  const CallGlobalStatistic = useCallback(() => {
    try {
      const queryResult = GlobalStatistics();
      return queryResult;
    } catch (error) {
      console.log(error);
    }
  }, []);
  const globalData = CallGlobalStatistic();

  const handleMetamask = () => {
    connect(WalletType.MetaMask);
  };
  const getLocalStatistics = useCallback(() => {
    if (account && account.length > 0) {
      setSearchParams({ account: account });
      getLocalStatisticsFn({
        variables: {
          address: prepareAddress(account),
        },
      });
    }
    if (paramAccount !== null && paramAccount !== account) {
      getLocalStatisticsFn({
        variables: {
          address: prepareAddress(paramAccount),
        },
      });
    }
  }, [account, getLocalStatisticsFn, setSearchParams, paramAccount]);

  const getBoosterStatistics = useCallback(() => {
    getBoosterWindowFn({
      variables: {},
    });
  }, [getBoosterWindowFn]);

  useEffect(() => {
    getBoosterStatistics();
  }, [getBoosterStatistics]);
  useEffect(() => {
    getLocalStatistics();
  }, [account, getLocalStatistics, tokenBalance]);

  useEffect(() => {
    getLocalStatistics();
  }, [account, getLocalStatistics, tokenBalance]);
  const afterStaking = useCallback(() => {
    const refreshData = async () => {
      const delays = [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89];
      for (let i = 0; i < delays.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, delays[i] * 1000));
        await getLocalStatistics();
      }
    };
    refreshData();
  }, [getLocalStatistics]);

  useEffect(() => {
    setBeWarning(globalData === "error");
  }, [globalData]);

  return (
    <div className="staking">
      <div className="header-text">
        {REACT_APP_RESTRUCTURE_TEXT && (
          <div id="important_section" className="be_warning_text">
            <p className="cell_important">{warning_text_restructured}</p>
          </div>
        )}

        <h1>Coinweb staking dashboard</h1>
        <p
          className="cell_important"
          id="error_message"
          style={{ marginTop: "20px" }}
        >
          Dear Staker, you must keep your CWEB rewards in your EVM wallet and
          not move them in order to maintain the additional privileges and
          compounding rewards!
        </p>
        {/* <p>{infoText}</p> */}
        <button
          onClick={() =>
            window.open(
              "https://medium.com/@Coinweb.io/guide-to-staking-cweb-8032b11599b1"
            )
          }
          id="guide_btn"
        >
          Guide to Staking CWEB
        </button>
      </div>

      {activeMetamask ? (
        <div style={{ marginBottom: "20px" }}>
          <WalletButton
            type="button"
            onClick={() => handleMetamask()}
            variant="contained"
            disabled={!!account}
          >
            {account ? "WALLET CONNECTED" : "CONNECT YOUR WALLET"}
          </WalletButton>
        </div>
      ) : null}

      {beWarning || !isReady ? (
        <div id="important_section">
          <p className="cell_important be_warning_text">{warning_text}</p>
        </div>
      ) : null}

      {account && (
        <>
          <StakeSection
            totalBoostStakedCweb={
              globalData !== "loading" &&
              globalData !== "error" &&
              globalData !== undefined &&
              globalData !== null
                ? globalData.currentBoostStakeCweb
                : 0
            }
            rewardPool={booster.booster.rewardPool}
            activeBooster={activeBooster}
            handleCweb={handleAmountToStake}
            metamaskAccount={account}
            afterStaking={afterStaking}
            model={model}
            availableBalanceForStake={availableBalanceForStake}
            isReady={isReady}
            amountToStakeError={amountToStakeError}
          ></StakeSection>
        </>
      )}
      {paramAccount && stakingPaid && data && data.length > 0 && (
        <RewardSection data={data}></RewardSection>
      )}
      <StakeDivider></StakeDivider>
      <div id="card_data_section">
        <MyStatisticsCard
          model={localStatisticsData ? model : undefined}
          rewardData={data}
          rewardsStaked={rewardsStaked}
        ></MyStatisticsCard>
        {width > 600 ? (
          <>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="available_boost"
                    className="card_icon shadow"
                    src={Boost}
                    width={47}
                  />
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title">Privileged Projects</p>
              </div>
              <div className="data_card_global_data">
                <ul className="data_card_list">
                  <li className="data_card_list_item">
                    <span className="cell_header cell_padding color_bone">
                      ImPACT Synthetics
                    </span>
                    {rewardsStaked ? (
                      <span className="status_badge eligible">Eligible</span>
                    ) : (
                      <span className="status_badge pending">Pending</span>
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="statistics"
                    className="card_icon shadow"
                    src={Statistics}
                    width={47}
                  ></img>
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title ">Statistics</p>
              </div>
              <div className="data_card_global_data">
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Total staked
                  </p>
                  {REACT_APP_TOTAL_STAKE_STATIS_OFF ? (
                    <p className="cell_data color_white">N/A</p>
                  ) : (
                    <p className="cell_data color_white">
                      {globalData !== undefined &&
                      globalData &&
                      globalData !== "loading" &&
                      globalData !== "error"
                        ? Number(
                            globalData.totalStakedCweb.toFixed(0)
                          ).toLocaleString() + " CWEB"
                        : "- -"}
                    </p>
                  )}
                </div>
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Number of stakers
                  </p>
                  <p className="cell_data color_white">
                    {globalData !== undefined &&
                    globalData &&
                    globalData !== "loading" &&
                    globalData !== "error"
                      ? Number(
                          globalData.amountOfStakers.toFixed(0)
                        ).toLocaleString()
                      : "- -"}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="available_boost"
                    className="card_icon shadow"
                    src={Boost}
                    width={47}
                  />
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title">Privileged Projects</p>
              </div>
              <div className="data_card_global_data">
                <ul className="data_card_list">
                  <li className="data_card_list_item">
                    <span className="cell_header cell_padding color_bone">
                      ImPACT Synthetics
                    </span>
                    {rewardsStaked ? (
                      <span className="status_badge eligible">Eligible</span>
                    ) : (
                      <span className="status_badge pending">Pending</span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="statistics"
                    className="card_icon shadow"
                    src={Statistics}
                    width={47}
                  ></img>
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title ">Statistics</p>
              </div>
              <div className="data_card_global_data">
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Total staked
                  </p>
                  {REACT_APP_TOTAL_STAKE_STATIS_OFF ? (
                    <p className="cell_data color_white">N/A</p>
                  ) : (
                    <p className="cell_data color_white">
                      {globalData !== undefined &&
                      globalData &&
                      globalData !== "loading" &&
                      globalData !== "error"
                        ? Number(
                            globalData.totalStakedCweb.toFixed(0)
                          ).toLocaleString() + " CWEB"
                        : "- -"}
                    </p>
                  )}
                </div>
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Amount of stakers
                  </p>
                  <p className="cell_data color_white">
                    {globalData !== undefined &&
                    globalData &&
                    globalData !== "loading" &&
                    globalData !== "error"
                      ? Number(
                          globalData.amountOfStakers.toFixed(0)
                        ).toLocaleString()
                      : "- -"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default memo(Staking);
