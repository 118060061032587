import { convertStakedDays } from "./convertStakedDays";
import { StakingData } from "../components/Staking/model";
export function calculateUnstaked(model: StakingData) {
  const rewardsDistributionDate =
    new Date("2024-11-14T00:00:00Z").getTime() / 1000;
  const stakedDays = model.forAddr.stakedCwebDays;
  const stakedDaysTimestamp = convertStakedDays(
    stakedDays,
    model.forAddr.totalStakedCweb
  );
  const currentTimeUTC = Math.floor(Date.now() / 1000);
  const firstStakedDay = currentTimeUTC - stakedDays * 24 * 60 * 60;
  const didUnstakeBeforeRewardsDay = rewardsDistributionDate < firstStakedDay;
  return didUnstakeBeforeRewardsDay;
}
