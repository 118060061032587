import MyStake from "../../assets/Icons/my_stake.svg";
import {
  REACT_APP_REWARD_RATE_OFF,
  REACT_APP_TOTAL_COLLATE_OFF,
  REACT_APP_TOTAL_REWARD_OFF,
  REACT_APP_TOTAL_STAKE_OFF,
} from "../../conf";
import {
  StakingData,
  calculateL2Collateral,
  calculateRewards,
} from "../Staking/model";
import { useState, useEffect, useMemo } from "react";
import { handleNumberDisplay } from "../Staking/RewardSection";
import { CSVData } from "../../types/index";
import { calculateUnstaked } from "../../utils/calculateUnstaked";

type MyStatisticsCardProps = {
  model: StakingData | undefined;
  rewardData: CSVData[] | undefined;
  rewardsStaked: boolean;
};

const MyStatisticsCard = (props: MyStatisticsCardProps) => {
  const { model, rewardData, rewardsStaked } = props;
  const [totalStakedRewards, setTotalStakedRewards] = useState<number>();
  const [isUserDoubleBurn, setUserDoubleBurn] = useState<boolean>(false);
  const [endReward, setEndReward] = useState<boolean>(false);

  useEffect(() => {
    if (model?.forAddr?.stakedCwebDays && rewardData && rewardData.length > 0) {
      const stakedCwebDays = Number(model.forAddr.stakedCwebDays);
      const totalToPay = Number(rewardData[0].total_to_pay);

      // Timestamp representing 14th November 2023, in seconds since epoch (UTC)
      const NOVEMBER_14_UTC_TIMESTAMP = 1731568500;

      if (isNaN(stakedCwebDays) || isNaN(totalToPay)) {
        console.error("Invalid numeric values detected:", {
          stakedCwebDays,
          totalToPay,
        });
        return;
      }

      const currentTimeUTC = Math.floor(Date.now() / 1000);
      const timeDifferenceInDays =
        (currentTimeUTC - NOVEMBER_14_UTC_TIMESTAMP) / 86400;

      if (isNaN(timeDifferenceInDays) || timeDifferenceInDays < 0) {
        console.error(
          "Time difference calculation resulted in an invalid number:",
          {
            timeDifferenceInDays,
          }
        );
        return;
      }

      const rewardsStakedDays = timeDifferenceInDays * totalToPay;
      const stakedRewardsTotalReward = (rewardsStakedDays / 365) * 0.15;
      setTotalStakedRewards(stakedRewardsTotalReward);

      if (
        rewardData[0].collateral !== 0 &&
        rewardData[0].staked !== 0 &&
        rewardData[0].collateral === rewardData[0].staked
      ) {
        const stakedDifference =
          model.forAddr.totalStakedCweb - model.forAddr.totalUnstakedCweb;
        const collateralDifference =
          model.forAddr.totalStakedCwebCollateral -
          model.forAddr.totalForfeitedCwebCollateral;
        const roundedStakedDif = parseFloat(stakedDifference.toFixed(3));
        const roundedCollateralDif = parseFloat(
          collateralDifference.toFixed(3)
        );
        if (roundedStakedDif === roundedCollateralDif) setUserDoubleBurn(true);
      }
    }
  }, [model, rewardData]);
  useEffect(() => {
    if (model) setEndReward(calculateUnstaked(model));
  }, [model, setEndReward]);

  const getCwebCollateralDisplay = useMemo(() => {
    if (isUserDoubleBurn) {
      return "0 CWEB";
    }
    if (
      model?.forAddr?.totalStakedCwebCollateral !== undefined &&
      model.forAddr.totalForfeitedCwebCollateral !== undefined
    ) {
      if (rewardData?.[0]?.total_collateral) {
        if (!endReward) {
          return (
            (
              calculateL2Collateral(model) - rewardData[0].total_collateral
            ).toLocaleString() + " CWEB"
          );
        }
        return calculateL2Collateral(model).toLocaleString() + " CWEB";
      }
      return calculateL2Collateral(model).toLocaleString() + " CWEB";
    }

    return "- -";
  }, [model, rewardData, endReward, isUserDoubleBurn]);

  const getCwebRewardDisplay = useMemo(() => {
    if (isUserDoubleBurn) {
      return "0 CWEB";
    }

    if (
      model?.forAddr?.totalAccumulatedL2Rewards !== undefined &&
      model.forAddr.totalForfeitedL2Rewards !== undefined
    ) {
      if (rewardData?.[0]?.reward) {
        if (!endReward) {
          return (
            (
              Number(
                model.forAddr.totalAccumulatedL2Rewards -
                  model.forAddr.totalForfeitedL2Rewards
              ) - rewardData[0].reward
            ).toLocaleString() + " CWEB"
          );
        }
        return (
          Number(
            model.forAddr.totalAccumulatedL2Rewards -
              model.forAddr.totalForfeitedL2Rewards
          ).toLocaleString() + " CWEB"
        );
      }
      return (
        (
          model.forAddr.totalAccumulatedL2Rewards -
          model.forAddr.totalForfeitedL2Rewards
        ).toLocaleString() + " CWEB"
      );
    }

    return "- -";
  }, [model, rewardData, endReward, isUserDoubleBurn]);
  return (
    <div
      className="data_card"
      style={{ padding: "20px 5px 20px 20px !important" }}
    >
      <div className="data_card_header color_white">
        <div className="card_icon_container">
          <img
            className="card_icon shadow"
            src={MyStake}
            width={47}
            alt="mystake"
          ></img>
          <span className="stripe-right header_stripe"></span>
        </div>
        <p className="card_header_title ">My Coinweb Staking</p>
      </div>
      <div id="data_card_mystake_data">
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">
            Total staked ERC20
          </p>
          {REACT_APP_TOTAL_STAKE_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">
              {isUserDoubleBurn
                ? "0 CWEB"
                : model !== undefined &&
                  model.forAddr.currentStakedCweb !== undefined &&
                  model.forAddr.currentStakedCweb !== null
                ? rewardData && rewardData[0]?.unstake_date_s
                  ? (
                      model.forAddr.currentStakedCweb - rewardData[0].staked
                    ).toLocaleString() + " CWEB"
                  : model.forAddr.currentStakedCweb.toLocaleString() + " CWEB"
                : "- -"}
            </p>
          )}
        </div>
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">
            New ERC20 rewards (15%)
          </p>
          {REACT_APP_TOTAL_REWARD_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">{getCwebRewardDisplay}</p>
          )}
        </div>
        {rewardsStaked && !isUserDoubleBurn && (
          <div className="data_card_grid_cell">
            <p className="cell_header cell_padding color_bone">
              Distributed native rewards (15%)
            </p>

            <p className="cell_data color_white">
              {rewardData && rewardData[0].total_to_pay
                ? handleNumberDisplay(rewardData[0].total_to_pay) + " CWEB"
                : "- -"}
            </p>
          </div>
        )}
        {rewardsStaked && !isUserDoubleBurn && (
          <div className="data_card_grid_cell">
            <p className="cell_header cell_padding color_bone">
              Compounding native rewards (15%)
            </p>

            <p className="cell_data color_white">
              {totalStakedRewards
                ? handleNumberDisplay(totalStakedRewards) + " CWEB"
                : "- -"}
            </p>
          </div>
        )}

        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">
            Total L2 collateral
          </p>
          {REACT_APP_TOTAL_COLLATE_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">{getCwebCollateralDisplay}</p>
          )}
        </div>

        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">
            <a
              href="https://evm-wallet-link.coinweb.io/?from=coinwebEVM&to=coinweb"
              target="_blank"
              rel="noopener noreferrer"
              className="link_style"
            >
              You can check your distributed token on this page.
            </a>
          </p>
        </div>
      </div>
      {endReward && (
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding " id="error_test">
            Unfortunately you are not eligible to collect the rewards.
          </p>
        </div>
      )}
    </div>
  );
};

export default MyStatisticsCard;
